import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
function Navbar() {
    return (
        <nav>
            <ul>
                <li>
                    <Link to="/">Anonymous</Link>
                </li>
                <li>
                    <Link to="/authenticated">Authenticated</Link>
                </li>
                <li>
                    <Link to="/custom">Custom</Link>
                </li>
            </ul>
        </nav>);
}
export default Navbar; 