import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import Anonymous from "./pages/anonymous";
import Authenticated from "./pages/authenticated";
import Custom from "./pages/custom";

export default function MyGPT() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Anonymous />} />
          <Route path="Authenticated" element={<Authenticated />} />
          <Route path="Custom" element={<Custom />} />
        </Route>
      </Routes>
    </BrowserRouter>);
}
ReactDOM.render(
  <MyGPT />,
  document.getElementById("root")
);